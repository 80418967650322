/* eslint-disable @typescript-eslint/promise-function-async */
import { Routes } from '@angular/router';

import { ConnectedGuard } from '@core/guards';
import { KCAuthGuard } from './core/guards/kc-auth.guard';

export const ROUTES = {
  LANDING_PAGE: 'start',
  DEVICE_PREP: 'device-prep',
  DEVICE_DISCOVERY: 'device-discovery',
  DEVICE_CONNECTED: 'device-connected',
  MAIN: 'main',
  CONNECTION_LOST: 'connection-lost',
  DYNAMIC_LINK: 'dynamicLink'
};

export const MAIN_ROUTES = {
  RADIO_SETTINGS: 'radio-settings',
  GENERAL_SETTINGS: 'general-settings',
  INFO: 'information',
  MAINTENANCE: 'maintenance',
  ADDITIONAL_FUNCTIONS: 'additional-functions',
  INSTALLATION: 'installation',
  ERROR_STATUSES: 'error-statuses',
  ERROR_LOG: 'error-log',
  FLASH_DEVICE: 'flash-device',
  SETTINGS_COLLECTION: 'settings-collection',
  PERSIST_SETTINGS: 'persist-settings'
};

export const appRoutes: Routes = [
  {
    path: '',
    redirectTo: ROUTES.LANDING_PAGE,
    pathMatch: 'full'
  },
  {
    path: ROUTES.DEVICE_CONNECTED,
    canLoad: [ConnectedGuard],
    loadChildren: () =>
      import('./features/device-connected/device-connected.module').then(
        (m) => m.DeviceConnectedModule
      ),
      canActivate: [KCAuthGuard]
  },
  {
    path: ROUTES.DEVICE_DISCOVERY,
    loadChildren: () =>
      import('./features/device-discovery/device-discovery.module').then(
        ({ DeviceDiscoveryModule }) => DeviceDiscoveryModule
      ),
      canActivate: [KCAuthGuard]
  },
  {
    path: ROUTES.DEVICE_PREP,
    loadChildren: () =>
      import('./features/device-prep/device-prep.module').then(
        ({ DevicePrepModule }) => DevicePrepModule
      ),
      canActivate: [KCAuthGuard]
  },
  {
    path: ROUTES.MAIN,
    canLoad: [ConnectedGuard],
    loadChildren: () =>
      import('./features/main/main.module').then(({ MainModule }) => MainModule),
    canActivate: [KCAuthGuard]
  },
  {
    path: ROUTES.CONNECTION_LOST,
    loadChildren: () =>
      import('./features/connection-lost/connection-lost.module').then(
        ({ ConnectionLostModule }) => ConnectionLostModule
      ),
      canActivate: [KCAuthGuard]
  },
  {
    path: ROUTES.LANDING_PAGE,
    loadChildren: () =>
      import('./features/start/start.module').then(
        ({ StartModule }) => StartModule
      )
  },
  {
    path: '**',
    redirectTo: ROUTES.LANDING_PAGE,
    pathMatch: 'full'
  }
];
//
// export const ROUTES_WITH_HEADER = [
//   `/${ROUTES.DEVICE_CONNECTED}`,
//   `/${ROUTES.DEVICE_DISCOVERY}`,
//   `/${ROUTES.DEVICE_PREP}`,
//   `/${ROUTES.MAIN}`,
//   `/${ROUTES.MAIN}/${MAIN_ROUTES.INFO}`,
//   `/${ROUTES.MAIN}/${MAIN_ROUTES.INSTALLATION}`,
//   `/${ROUTES.MAIN}/${MAIN_ROUTES.INSTALLATION}/${MAIN_ROUTES.INSTALLATION_CUSTOMIZE_WARNING}`,
//   `/${ROUTES.MAIN}/${MAIN_ROUTES.INSTALLATION}/${MAIN_ROUTES.INSTALLATION_LOSING_POSITION_WARNING}`,
//   `/${ROUTES.MAIN}/${MAIN_ROUTES.INSTALLATION}/${MAIN_ROUTES.INSTALLATION_CUSTOMIZE_OPENED_SETUP}`,
//   `/${ROUTES.MAIN}/${MAIN_ROUTES.INSTALLATION}/${MAIN_ROUTES.INSTALLATION_CUSTOMIZE_CLOSED_SETUP}`,
//   `/${ROUTES.MAIN}/${MAIN_ROUTES.INSTALLATION}/${MAIN_ROUTES.INSTALLATION_CUSTOMIZE_SETUP_SAVE}`,
//   `/${ROUTES.CONNECTION_LOST}`
// ];

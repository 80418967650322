import { Injectable, NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {
  TRANSLOCO_CONFIG,
  TRANSLOCO_LOADER,
  Translation,
  TranslocoLoader,
  TranslocoModule,
  translocoConfig
} from '@ngneat/transloco';

import { environment } from '../../environments/environment';
import { StorageService } from '@app/shared/services/storage.service';
import { Observable, of } from 'rxjs';

const AVAILABLE_LANGS = ['en', 'de', 'es', 'fr', 'it', 'nl'];
export const DEFAULT_LANG = 'en';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private readonly http: HttpClient, private readonly _storageService: StorageService) {}

  getTranslation(lang: string) {

    let translation: Observable<Translation> | null = null;
    try {
      translation = of<Translation>(this._storageService.getLanguageData(lang) as Translation);
    } catch (error) {
      console.log('[TranslocoModule] getTranslation - from storage: ', error);
    }

    if (!translation) {
      translation = this.http.get<Translation>(`/assets/i18n/${lang}.json`);
    }

    return translation;
  }
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        reRenderOnLangChange: true,
        availableLangs: AVAILABLE_LANGS,
        defaultLang: DEFAULT_LANG,
        fallbackLang: DEFAULT_LANG,
        missingHandler: {
          logMissingKey: false
        },
        // Remove this option if your application doesn't support changing language in runtime.
        // reRenderOnLangChange: true,
        prodMode: environment.production
      })
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader }
  ]
})
export class TranslocoRootModule {}

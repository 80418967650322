import { Injectable } from "@angular/core";
import { BroadcastCategory, DoorLearnStateEnum } from "@app/shared/enums/enums";
import { BroadcastRegister } from "@app/shared/interfaces/interfaces";
import { createArrayNumbersFromTo } from "@app/utils";
import { BehaviorSubject, Observable, Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class BroadcastService {
  registeredIdsKVP: Record<number, BroadcastRegister> = {};
  registerableIdsKVP: Record<number, BroadcastRegister> = {};
  registerIdsEvent: Subject<number[]> = new Subject<number[]>();
  unregisterIdsEvent: Subject<number[]> = new Subject<number[]>();
  private _broadcastItems: BroadcastRegister[];

  constructor() {}

  init() {
    this._setAllBroadcasts();
    this._registerInitialBroadcasts();
  }

  async registerIds(ids: number[]) {
    const unregisteredIds: number[] = [];
    ids.forEach(id => {
      if (!Object.prototype.hasOwnProperty.call(this.registeredIdsKVP, id)) {
        this.registeredIdsKVP[id] = this.getById(id);
        if (this.registeredIdsKVP[id]) {
          unregisteredIds.push(id);
        }
      } else if (!this.registeredIdsKVP[id].isRegistered) {
        unregisteredIds.push(id);
      }
    });
    if (unregisteredIds.length > 0) {
      this.registerIdsEvent.next(unregisteredIds);
    }
  }

  unregisterIds(ids: number[]) {
    const registeredIds: number[] = [];
    ids.forEach(id => {
      if (Object.prototype.hasOwnProperty.call(this.registeredIdsKVP, id)) {
        registeredIds.push(id);
        delete this.registeredIdsKVP[id];
      }
    });

    if (registeredIds.length > 0) {
      this.unregisterIdsEvent.next(registeredIds);
    }
  }

  get broadcastItems() {
    if (!this._broadcastItems || this._broadcastItems.length === 0) {
      this._broadcastItems = BROADCAST_ITEMS;
      this._broadcastItems.forEach(item => {
        item.isRegistered = false;
        if (!item?.observable) {
          item.observable = new BehaviorSubject<number[]>([]);
        }
      });
    }

    return this._broadcastItems;
  }

  getBroadcastById(id: number) {
    return this.broadcastItems.find(item => item.id === id);
  }

  registerBroadcastsByCategory(category: BroadcastCategory) {
    const ids = this._getBroadcastsByCategory(category).map(item => item.id);
    return this.registerIds(ids);
  }

  unregisterBroadcastsByCategory(category: BroadcastCategory) {
    const ids = this._getBroadcastsByCategory(category).map(item => item.id);
    return this.unregisterIds(ids);
  }

  getById(id: number) {
    if (Object.prototype.hasOwnProperty.call(this.registerableIdsKVP, id)) {
      return this.registerableIdsKVP[id];
    } else {
      throw new Error('BroadcastService - getById - no such id: ' + id);
    }
  }

  getObservableById(id: number): Observable<unknown> | undefined{
    return this.getById(id)?.observable;
  }

  setAsRegistered(id: number, isRegistered: boolean) {
    if (Object.prototype.hasOwnProperty.call(this.registeredIdsKVP, id)) {
      this.registeredIdsKVP[id].isRegistered = isRegistered;
    } else {
      console.log('[BroadcastService] setAsRegistered - id unknown: ', id);
    }
  }

  areAllStartIdsRegistered() {
    const initialIds = this.broadcastItems.filter(item => item.isInitial).map(item => item.id);
    return this.areAllTheseIdsRegistered(initialIds);
  }

  areAllTheseIdsRegistered(ids: number[]) {
    const registeredIds = ids.filter(id => this.registeredIdsKVP[id]?.isRegistered);
    // todo: should we wait for all or can we go on if some are missing?
    return ids.length - registeredIds.length < 3;
  }

  setRegisterableBroadcasts(broadcasts: BroadcastRegister[], doRegister = false) {
    broadcasts.forEach(item => {
      if (!Object.prototype.hasOwnProperty.call(this.registerableIdsKVP, item.id)) {
        this.registerableIdsKVP[item.id] = item;
      }
    });
    if (doRegister) {
      const ids = broadcasts.map((cast) => cast.id);
      this.registerIds(ids);
    }
  }

  reset() {
    this._broadcastItems.forEach(item => {
      if (Object.prototype.hasOwnProperty.call(this.registeredIdsKVP, item.id)) {
        this.registeredIdsKVP[item.id].isRegistered = false;
      }
    });
  }

  private _registerInitialBroadcasts() {
    const initialIds = this.broadcastItems.filter(item => item.isInitial).map(item => item.id);

    this.registerIds(initialIds);
  }

  // set all broadcast objects taken from the "Signalkatalog"
  private _setAllBroadcasts() {
    this.setRegisterableBroadcasts(this.broadcastItems);
  }

  private _getBroadcastsByCategory(category?: BroadcastCategory) {
    if (category) {
      return this.broadcastItems.filter(item => item.category === category);
    } else {
      return this.broadcastItems;
    }
  }
}

export enum CommId {
   // Alive signal
  ID_ALIVE_SIGNAL = 157,

   // Informations readout
  ID_HARDWARE_VERSION = 31,
  ID_VERSION_SAFETY_SW = 65528,
  ID_VERSION_COMFORT_SW = 48,
  ID_SERIAL_NUMBER = 20,
  ID_DEVICE_ID = 141,
  ID_DEVICE_NAME = 145,
  ID_DOOR_CYCLE_CTR = 40,
  ID_POWER_ON_HOURS = 32,
  ID_DOOR_RUN_TIMER_TOTAL = 38,
  ID_OPEN_TIME_CTR = 71,
  ID_CURRENT_DOOR_POSITION_IN_PERCENT = 36,
  ID_CURRENT_VALUE_AWG = 37,
  ID_DOOR_LEARN_STATE = 27,
  ID_AWG_SERIAL = 112,
  ID_READ_MCP_DISPLAY_SW_VERSION = 115,
 
   // Setup/Test positions
  ID_END_POSITION_TYPE = 2,
  ID_ROTATING_FIELD = 1,
  ID_LEARNING_COMMAND = 5,
  ID_LEARNING_IME_POSITION = 56,
  ID_DELETE_IME_POSITION = 65,
  ID_INTERMEDIATE_POSITIONS = 4,
  ID_IMPULS_TARGET = 44,
  ID_MOTOR_STATE = 25,
  ID_CURRENT_DOOR_POSITION = 47,
  ID_DIAGNOSIS_END_POSITION_FINE_SETTINGS = 102,
 
   // General Settings
  ID_FACTORY_RESET = 7,
  ID_RESTART = 29,
  ID_BEHAVIOUR_ON_BUTTON_REQUEST = 45,
  ID_DELETE_ALL_ERRORS = 114,
 
   // Radio Settings
  ID_RADIO_COMMAND = 85,
  ID_IS_BILINKED = 140,
  ID_RADIO_LEARN_STATE = 137,
 
   // Running Method
  ID_RUNNING_METHOD = 6,
 
   // Timers
  ID_CAUTION_WARNING_TIME = 9,
  ID_START_WARNING_TIME = 12,
  ID_OPEN_TIME_AND_ACTIVATION = 43,
 
   // Traffic Light
  ID_TRAFFIC_LIGHT_REST = 131,
  ID_TRAFFIC_LIGHT_BEHAVIOUR = 135,
 
   // Safety Features
  ID_SAFETY_FEATURE_TERMINAL_1 = 300,
  ID_SAFETY_FEATURE_TERMINAL_2 = 301,
  ID_SAFETY_FEATURE_TERMINAL_3 = 302,
  ID_SAFETY_FEATURE_TERMINAL_4 = 303,
  ID_SAFETY_FEATURE_ELEMENT_AWG_1 = 310,
  ID_SAFETY_FEATURE_ELEMENT_AWG_2 = 311,
  ID_SAFETY_FEATURE_ELEMENT_AWG_3 = 312,
 
   // Maintenance
  ID_NUMBER_OF_DOOR_CYCLES_TO_MAINTENANCE = 64,
  ID_DIRECTION_CHANGE_TO_MAINTENANCE = 66,
 
   // Errors
  ID_ERROR_SAFETY_ELEMEMT_1_TRIGGERED = 5000,
  ID_ERROR_RUN_IN_NEEDED = 5001,
  ID_ERROR_SAFETY_EDGE_NEEDED = 5002,
  ID_ERROR_AWG_ROTATION_DIRECTION = 5004,
  ID_ERROR_AWG = 5005,
  ID_ERROR_MECH_ENDSCHALTER = 5006,
  ID_ERROR_MOTOR = 5007,
  ID_ERROR_WDG = 5008,
  ID_AWG_SAFETY_ELEMENT_NEEDED = 5009,
  ID_ERROR_INVALID_ENDPOSITIONS = 5011,
  ID_ERROR_SAFETY_ELEMEMT_7_TRIGGERED = 5012,
  ID_ERROR_SAFETY_ELEMEMT_8_TRIGGERED = 5013,
  ID_ERROR_SAFETY_ELEMEMT_9_TRIGGERED = 5014,
  ID_ERROR_SAFETY_ELEMEMT_2_TRIGGERED = 5018,
  ID_ERROR_BREAK_TRIGGERED = 5019,
  ID_ERROR_SAFETY_ELEMEMT_3_TRIGGERED = 5020,
  ID_ERROR_SAFETY_ELEMEMT_4_TRIGGERED = 5021,
  ID_ERROR_AWG_NOT_TURNING = 5022,
  ID_ERROR_TEST_FAILED = 5023,
  ID_ERROR_BUTTON_DEFECT = 5026,
  ID_ERROR_RUNTIME = 5027,
  ID_ERROR_WICKET_DOOR_LOCK = 5028,
  ID_ERROR_AC_FORCE = 5029,
  ID_ERROR_GPIO_MECH_END_OPEN = 5034,
  ID_ERROR_GPIO_MECH_END_CLOSE = 5035,
  ID_ERROR_GPIO_UP_PCB = 5036,
  ID_ERROR_GPIO_DOWN_PCB = 5037,
  ID_ERROR_GPIO_STOP_PCB = 5038,
  ID_ERROR_GPIO_UP_EXTERN = 5039,
  ID_ERROR_GPIO_DOWN_EXTERN = 5040,
  ID_ERROR_GPIO_STOP_EXTERN = 5041,
  ID_ERROR_GPIO_UP_CSI = 5043,
  ID_ERROR_GPIO_DOWN_CSI = 5044,
  ID_ERROR_GPIO_STOP_CSI = 5045,
  ID_ERROR_DW_TESTING = 5046,
  ID_ERROR_GPIO_UP_COVER = 5047,
  ID_ERROR_GPIO_DOWN_COVER = 5048,
  ID_ERROR_GPIO_STOP_COVER = 5049,
  ID_ERROR_DEBUG1 = 5051,
  ID_ERROR_DEBUG2 = 5052,
  ID_ERROR_DEBUG3 = 5053,
  ID_ERROR_DEBUG4 = 5054,
  ID_ERROR_DEBUG5 = 5055,
  ID_ERROR_DEBUG6 = 5056,
  ID_ERROR_DEBUG7 = 5057,
  ID_ERROR_DEBUG8 = 5058,
  ID_ERROR_DEBUG9 = 5059,
  ID_ERROR_DEBUG10 = 5060,
  ID_ERROR_SAFETY_CHAIN_COVER_XB6 = 5061,
  ID_ERROR_SAFETY_CHAIN_MOTOR_B1_B2 = 5062,
  ID_ERROR_SAFETY_CHAIN_XB3_4 = 5063,
  ID_ERROR_SAFETY_CHAIN_MECH_ENDSWITCHES = 5064,
  ID_ERROR_24V_SUPPLY = 5065,
  ID_ERROR_MAINTENENCE_NEEDED = 5066,
  ID_ERROR_BUS_SAFETY_ELEMEMT_1_TRIGGERED = 5500,
  ID_ERROR_BUS_SAFETY_ELEMEMT_2_TRIGGERED = 5502,
  ID_ERROR_BUS_SAFETY_ELEMEMT_3_TRIGGERED = 5505,
 
   // Prog In
  ID_IMPULSE_PROG_INPUT_SET_VALUE = 129,
  BREAK_SURVEILLANCE_SET_VALUE = 136,
 
   // Prog Output
  GET_PROG_OUTPUT_STATE_1 = 49,
  GET_PROG_OUTPUT_STATE_2 = 50,
  GET_PROG_OUTPUT_STATE_3 = 51,
  GET_PROG_OUTPUT_STATE_4 = 42,
 
  SET_PROG_OUTPUT_STATE_1 = 52,
  SET_PROG_OUTPUT_STATE_2 = 53,
  SET_PROG_OUTPUT_STATE_3 = 54,
  SET_PROG_OUTPUT_STATE_4 = 17,
 
   // installation
  ID_MECHANICAL_END_POSITION_RESET = 18,
  ID_AWG_TOLERANCE = 41,
  ID_MECHANICAL_END_POSITION_RUNTIME = 105,
  ID_SP_DEACTIVATION_OPEN = 138,
  ID_SP_DEACTIVATION_CLOSE = 139,
 
  ID_LS_POINT = 146,
  ID_SAFETY_EDGE_TEST_POINT = 147,

  // settings collection
  ID_DISABLE_RADIO_FEATURE = 8,
  ID_CAUTION_WARNING_ACTIVITY_ON_INTERRUPTION = 11,
  ID_START_WARNING_ACTIVITY_ON_INTERRUPTION = 14,
  ID_SC_SET_PROG_OUTPUT_4 = 17,
  ID_NUMBER_OF_DOOR_CYCLES = 19,
  ID_DOOR_RUN_TIMER_TO_MAINTENANCE = 22,
  ID_DOOR_RUN_TIMER = 23,
  ID_POWER_ON_HOURS_RESETABLE = 34,
  ID_RUNTIME_DEVIATION_PERCENT = 35,
  ID_MOTOR_LOCK_COUNTER = 55,
  ID_DIRECTION_CHANGE = 67,
  ID_HOLIDAY_MODUS = 81,
  ID_AWG_COUNTS_UNTIL_STOP = 104,
  ID_IMPULSE_RADIO_AND_EXTERN = 128,
}


  // Error logs
  export const ERROR_LOG_IDS = createArrayNumbersFromTo(6000, 6020);

  export const BROADCAST_ITEMS: BroadcastRegister[] = [
    {
      id: CommId.ID_DOOR_LEARN_STATE,
      isInitial: true,
      rule: (values: number[]) => {
        return [DoorLearnStateEnum[values[0]]];
      },
      category: BroadcastCategory.INFORMATION
    }, {
      id: CommId.ID_HARDWARE_VERSION,
      isInitial: true,
      category: BroadcastCategory.INFORMATION
    }, {
      id: CommId.ID_VERSION_SAFETY_SW,
      isInitial: true,
      rule: (values: number[]) => {
        return values.join('.');
      },
      category: BroadcastCategory.INFORMATION
    }, {
      id: CommId.ID_VERSION_COMFORT_SW,
      isInitial: true,
      rule: (values: number[]) => {
        return values.join('.');
      },
      category: BroadcastCategory.INFORMATION
    }, {
      id: CommId.ID_SERIAL_NUMBER,
      isInitial: true,
      category: BroadcastCategory.INFORMATION
    }, {
      id: CommId.ID_DEVICE_ID,
      isInitial: true,
      category: BroadcastCategory.INFORMATION
    }, {
      id: CommId.ID_DEVICE_NAME,
      isInitial: false,
      category: BroadcastCategory.INFORMATION
    }, {
      id: CommId.ID_DOOR_CYCLE_CTR,
      isInitial: true,
      category: BroadcastCategory.INFORMATION
    }, {
      id: CommId.ID_POWER_ON_HOURS,
      isInitial: true,
      category: BroadcastCategory.INFORMATION
    }, {
      id: CommId.ID_DOOR_RUN_TIMER_TOTAL,
      isInitial: true,
      category: BroadcastCategory.INFORMATION
    }, {
      id: CommId.ID_CURRENT_DOOR_POSITION_IN_PERCENT,
      isInitial: true,
      category: BroadcastCategory.INFORMATION
    }, {
      id: CommId.ID_CURRENT_DOOR_POSITION,
      isInitial: true,
      category: BroadcastCategory.INFORMATION
    }, {
      id: CommId.ID_CURRENT_VALUE_AWG,
      isInitial: true,
      category: BroadcastCategory.INFORMATION
    }, {
      id: CommId.ID_AWG_SERIAL,
      isInitial: true,
      category: BroadcastCategory.INFORMATION
    }, {
      id: CommId.ID_READ_MCP_DISPLAY_SW_VERSION,
      isInitial: true,
      category: BroadcastCategory.INFORMATION
    }, {
      id: CommId.ID_ERROR_SAFETY_ELEMEMT_1_TRIGGERED,
      isInitial: true,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_ERROR_RUN_IN_NEEDED,
      isInitial: true,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_ERROR_SAFETY_EDGE_NEEDED,
      isInitial: true,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_ERROR_AWG_ROTATION_DIRECTION,
      isInitial: true,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_ERROR_AWG,
      isInitial: true,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_ERROR_MECH_ENDSCHALTER,
      isInitial: true,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_ERROR_MOTOR,
      isInitial: true,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_ERROR_WDG,
      isInitial: true,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_AWG_SAFETY_ELEMENT_NEEDED,
      isInitial: true,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_ERROR_INVALID_ENDPOSITIONS,
      isInitial: true,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_ERROR_SAFETY_ELEMEMT_7_TRIGGERED,
      isInitial: true,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_ERROR_SAFETY_ELEMEMT_8_TRIGGERED,
      isInitial: true,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_ERROR_SAFETY_ELEMEMT_9_TRIGGERED,
      isInitial: true,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_ERROR_SAFETY_ELEMEMT_2_TRIGGERED,
      isInitial: true,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_ERROR_BREAK_TRIGGERED,
      isInitial: false,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_ERROR_SAFETY_ELEMEMT_3_TRIGGERED,
      isInitial: true,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_ERROR_SAFETY_ELEMEMT_4_TRIGGERED,
      isInitial: true,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_ERROR_AWG_NOT_TURNING,
      isInitial: true,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_ERROR_TEST_FAILED,
      isInitial: false,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_ERROR_BUTTON_DEFECT,
      isInitial: true,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_ERROR_RUNTIME,
      isInitial: true,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_ERROR_WICKET_DOOR_LOCK,
      isInitial: true,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_ERROR_AC_FORCE,
      isInitial: true,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_ERROR_GPIO_MECH_END_OPEN,
      isInitial: true,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_ERROR_GPIO_MECH_END_CLOSE,
      isInitial: true,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_ERROR_GPIO_UP_PCB,
      isInitial: true,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_ERROR_GPIO_DOWN_PCB,
      isInitial: true,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_ERROR_GPIO_STOP_PCB,
      isInitial: true,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_ERROR_GPIO_UP_EXTERN,
      isInitial: true,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_ERROR_GPIO_DOWN_EXTERN,
      isInitial: true,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_ERROR_GPIO_STOP_EXTERN,
      isInitial: true,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_ERROR_GPIO_UP_CSI,
      isInitial: true,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_ERROR_GPIO_DOWN_CSI,
      isInitial: true,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_ERROR_GPIO_STOP_CSI,
      isInitial: true,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_ERROR_DW_TESTING,
      isInitial: true,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_ERROR_GPIO_UP_COVER,
      isInitial: true,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_ERROR_GPIO_DOWN_COVER,
      isInitial: true,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_ERROR_GPIO_STOP_COVER,
      isInitial: true,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_ERROR_DEBUG1,
      isInitial: true,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_ERROR_DEBUG2,
      isInitial: true,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_ERROR_DEBUG3,
      isInitial: true,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_ERROR_DEBUG4,
      isInitial: true,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_ERROR_DEBUG5,
      isInitial: true,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_ERROR_DEBUG6,
      isInitial: true,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_ERROR_DEBUG7,
      isInitial: true,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_ERROR_DEBUG8,
      isInitial: true,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_ERROR_DEBUG9,
      isInitial: true,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_ERROR_DEBUG10,
      isInitial: true,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_ERROR_SAFETY_CHAIN_COVER_XB6,
      isInitial: true,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_ERROR_SAFETY_CHAIN_MOTOR_B1_B2,
      isInitial: true,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_ERROR_SAFETY_CHAIN_XB3_4,
      isInitial: true,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_ERROR_SAFETY_CHAIN_MECH_ENDSWITCHES,
      isInitial: true,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_ERROR_24V_SUPPLY,
      isInitial: true,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_ERROR_MAINTENENCE_NEEDED,
      isInitial: true,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_ERROR_BUS_SAFETY_ELEMEMT_1_TRIGGERED,
      isInitial: true,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_ERROR_BUS_SAFETY_ELEMEMT_2_TRIGGERED,
      isInitial: true,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_ERROR_BUS_SAFETY_ELEMEMT_3_TRIGGERED,
      isInitial: true,
      category: BroadcastCategory.ERROR_STATUS
    }, {
      id: CommId.ID_RUNNING_METHOD,
      isInitial: true,
      category: BroadcastCategory.ADDITIONAL_FUNCTIONS
    }, {
      id: CommId.ID_IMPULSE_PROG_INPUT_SET_VALUE,
      isInitial: true,
      category: BroadcastCategory.ADDITIONAL_FUNCTIONS
    }, {
      id: CommId.BREAK_SURVEILLANCE_SET_VALUE,
      isInitial: true,
      category: BroadcastCategory.ADDITIONAL_FUNCTIONS
    }, {
      id: CommId.SET_PROG_OUTPUT_STATE_1,
      isInitial: true,
      category: BroadcastCategory.ADDITIONAL_FUNCTIONS
    }, {
      id: CommId.SET_PROG_OUTPUT_STATE_2,
      isInitial: true,
      category: BroadcastCategory.ADDITIONAL_FUNCTIONS
    }, {
      id: CommId.SET_PROG_OUTPUT_STATE_3,
      isInitial: true,
      category: BroadcastCategory.ADDITIONAL_FUNCTIONS
    }, {
      id: CommId.SET_PROG_OUTPUT_STATE_4,
      isInitial: true,
      category: BroadcastCategory.ADDITIONAL_FUNCTIONS
    }, {
      id: CommId.ID_OPEN_TIME_AND_ACTIVATION,
      isInitial: true,
      category: BroadcastCategory.ADDITIONAL_FUNCTIONS
    }, {
      id: CommId.ID_CAUTION_WARNING_TIME,
      isInitial: true,
      category: BroadcastCategory.ADDITIONAL_FUNCTIONS
    }, {
      id: CommId.ID_START_WARNING_TIME,
      isInitial: true,
      category: BroadcastCategory.ADDITIONAL_FUNCTIONS
    }, {
      id: CommId.ID_TRAFFIC_LIGHT_REST,
      isInitial: true,
      category: BroadcastCategory.ADDITIONAL_FUNCTIONS
    }, {
      id: CommId.ID_TRAFFIC_LIGHT_BEHAVIOUR,
      isInitial: true,
      category: BroadcastCategory.ADDITIONAL_FUNCTIONS
    }, {
      id: CommId.ID_SAFETY_FEATURE_TERMINAL_1,
      isInitial: true,
      category: BroadcastCategory.ADDITIONAL_FUNCTIONS
    }, {
      id: CommId.ID_SAFETY_FEATURE_TERMINAL_2,
      isInitial: true,
      category: BroadcastCategory.ADDITIONAL_FUNCTIONS
    }, {
      id: CommId.ID_SAFETY_FEATURE_TERMINAL_3,
      isInitial: true,
      category: BroadcastCategory.ADDITIONAL_FUNCTIONS
    }, {
      id: CommId.ID_SAFETY_FEATURE_TERMINAL_4,
      isInitial: true,
      category: BroadcastCategory.ADDITIONAL_FUNCTIONS
    }, {
      id: CommId.ID_SAFETY_FEATURE_ELEMENT_AWG_1,
      isInitial: true,
      category: BroadcastCategory.ADDITIONAL_FUNCTIONS
    }, {
      id: CommId.ID_SAFETY_FEATURE_ELEMENT_AWG_2,
      isInitial: true,
      category: BroadcastCategory.ADDITIONAL_FUNCTIONS
    }, {
      id: CommId.ID_SAFETY_FEATURE_ELEMENT_AWG_3,
      isInitial: true,
      category: BroadcastCategory.ADDITIONAL_FUNCTIONS
    }, {
      id: CommId.ID_IMPULSE_PROG_INPUT_SET_VALUE,
      isInitial: true,
      category: BroadcastCategory.ADDITIONAL_FUNCTIONS
    }, {
      id: CommId.BREAK_SURVEILLANCE_SET_VALUE,
      isInitial: true,
      category: BroadcastCategory.ADDITIONAL_FUNCTIONS
    }, {
      id: CommId.SET_PROG_OUTPUT_STATE_1,
      isInitial: true,
      category: BroadcastCategory.ADDITIONAL_FUNCTIONS
    }, {
      id: CommId.SET_PROG_OUTPUT_STATE_2,
      isInitial: true,
      category: BroadcastCategory.ADDITIONAL_FUNCTIONS
    }, {
      id: CommId.SET_PROG_OUTPUT_STATE_3,
      isInitial: true,
      category: BroadcastCategory.ADDITIONAL_FUNCTIONS
    }, {
      id: CommId.SET_PROG_OUTPUT_STATE_4,
      isInitial: true,
      category: BroadcastCategory.ADDITIONAL_FUNCTIONS
    }, {
      id: CommId.ID_BEHAVIOUR_ON_BUTTON_REQUEST,
      isInitial: true,
      category: BroadcastCategory.GENERAL_SETTINGS
    }, {
      id: CommId.ID_NUMBER_OF_DOOR_CYCLES_TO_MAINTENANCE,
      isInitial: true,
      category: BroadcastCategory.MAINTENANCE
    }, {
      id: CommId.ID_DIRECTION_CHANGE_TO_MAINTENANCE,
      isInitial: true,
      category: BroadcastCategory.MAINTENANCE
    }, {
      id: CommId.ID_MECHANICAL_END_POSITION_RUNTIME,
      isInitial: true,
      category: BroadcastCategory.INSTALLATION
    }, {
      id: CommId.ID_AWG_TOLERANCE,
      isInitial: true,
      category: BroadcastCategory.INSTALLATION
    }, {
      id: CommId.ID_SP_DEACTIVATION_OPEN,
      isInitial: true,
      category: BroadcastCategory.INSTALLATION
    }, {
      id: CommId.ID_SP_DEACTIVATION_CLOSE,
      isInitial: true,
      category: BroadcastCategory.INSTALLATION
    }, {
      id: CommId.ID_END_POSITION_TYPE,
      isInitial: true,
      category: BroadcastCategory.INSTALLATION
    }, {
      id: CommId.ID_ROTATING_FIELD,
      isInitial: true,
      category: BroadcastCategory.INSTALLATION
    }, {
      id: CommId.ID_DIAGNOSIS_END_POSITION_FINE_SETTINGS,
      isInitial: true,
      category: BroadcastCategory.INSTALLATION
    }, {
      id: CommId.ID_LS_POINT,
      isInitial: true,
      category: BroadcastCategory.INSTALLATION
    }, {
      id: CommId.ID_SAFETY_EDGE_TEST_POINT,
      isInitial: true,
      category: BroadcastCategory.INSTALLATION
    }, {
      id: CommId.ID_INTERMEDIATE_POSITIONS,
      isInitial: true,
      category: BroadcastCategory.INSTALLATION
    }, {
      id: CommId.ID_IS_BILINKED,
      isInitial: true,
      category: BroadcastCategory.RADIO_SETTINGS
    }, {
      id: CommId.ID_RADIO_LEARN_STATE,
      isInitial: true,
      category: BroadcastCategory.RADIO_SETTINGS
    }, {
      id: CommId.ID_DISABLE_RADIO_FEATURE,
      isInitial: true,
      category: BroadcastCategory.GENERAL_SETTINGS
    }, {
      id: CommId.ID_CAUTION_WARNING_ACTIVITY_ON_INTERRUPTION,
      isInitial: true,
      category: BroadcastCategory.GENERAL_SETTINGS
    }, {
      id: CommId.ID_START_WARNING_ACTIVITY_ON_INTERRUPTION,
      isInitial: true,
      category: BroadcastCategory.GENERAL_SETTINGS
    }, {
      id: CommId.ID_SC_SET_PROG_OUTPUT_4,
      isInitial: true,
      category: BroadcastCategory.GENERAL_SETTINGS
    }, {
      id: CommId.ID_NUMBER_OF_DOOR_CYCLES,
      isInitial: true,
      category: BroadcastCategory.GENERAL_SETTINGS
    }, {
      id: CommId.ID_DOOR_RUN_TIMER_TO_MAINTENANCE,
      isInitial: true,
      category: BroadcastCategory.GENERAL_SETTINGS
    }, {
      id: CommId.ID_DOOR_RUN_TIMER,
      isInitial: true,
      category: BroadcastCategory.GENERAL_SETTINGS
    }, {
      id: CommId.ID_POWER_ON_HOURS_RESETABLE,
      isInitial: true,
      category: BroadcastCategory.GENERAL_SETTINGS
    }, {
      id: CommId.ID_RUNTIME_DEVIATION_PERCENT,
      isInitial: true,
      category: BroadcastCategory.GENERAL_SETTINGS
    }, {
      id: CommId.ID_MOTOR_LOCK_COUNTER,
      isInitial: true,
      category: BroadcastCategory.GENERAL_SETTINGS
    }, {
      id: CommId.ID_DIRECTION_CHANGE,
      isInitial: true,
      category: BroadcastCategory.GENERAL_SETTINGS
    }, {
      id: CommId.ID_HOLIDAY_MODUS,
      isInitial: true,
      category: BroadcastCategory.GENERAL_SETTINGS
    }, {
      id: CommId.ID_AWG_COUNTS_UNTIL_STOP,
      isInitial: true,
      category: BroadcastCategory.GENERAL_SETTINGS
    }, {
      id: CommId.ID_IMPULSE_RADIO_AND_EXTERN,
      isInitial: true,
      category: BroadcastCategory.GENERAL_SETTINGS
    }, ...getErrorLogBroadcasts()
  ];
  
  export function getErrorLogBroadcasts() {
    return ERROR_LOG_IDS.map((id) => {
      return {
        id,
        isInitial: true,
        category: BroadcastCategory.ERROR_LOG
      }
    });
  }
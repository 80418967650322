import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

import { GridCard } from './grid-card.model';

@Component({
    selector: 'mcp-grid-card',
    templateUrl: 'grid-card.component.html',
    styleUrls: ['grid-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class GridCardComponent {
  @Input() public gridCardElement!: GridCard;

  @Output() public gridCardClicked: EventEmitter<string[]> = new EventEmitter<
    string[]
  >();

  public onCardClick(): void {
    this.gridCardClicked.emit(this.gridCardElement.route);
  }
}

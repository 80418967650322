import { Component, Inject } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ISimpleDialog } from '@app/shared/interfaces/interfaces';

@Component({
    selector: 'mcp-are-you-sure-dialog',
    templateUrl: 'are-you-sure-dialog.component.html',
    styleUrls: ['are-you-sure-dialog.component.scss'],
    standalone: false
})
export class AreYouSureDialogComponent
{
  constructor(@Inject(MAT_DIALOG_DATA) public data: ISimpleDialog) {}
}

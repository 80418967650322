import { Injectable } from '@angular/core';
import { Language, SettingsPersistPackage } from '../interfaces/interfaces';
import { Storage } from '../enums/enums';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor() {}

  setLanguage(language: Language) {
    localStorage.setItem(Storage.LANGUAGE, JSON.stringify(language));
  }

  getLanguage() {
    const languageString = localStorage.getItem(Storage.LANGUAGE);
    let parsed: Language | null = null;
    if (languageString) {
      parsed = JSON.parse(languageString) as Language;
    }
    return parsed;
  }

  getLanguageData(lang: string) {
    const data = localStorage.getItem('i18n_' + lang);
    if (data) {
      try {
        return JSON.parse(data);
      } catch (error) {
        console.log(error);
        return error;
      }
    } else {
      throw new Error('no data in storage'); 
    }  
  }

  setLanguageData(lang: string, data: any) {
    localStorage.setItem(lang, JSON.stringify(data));
  }

  addDeviceSetting(settings: SettingsPersistPackage) {
    const collectionsString = localStorage.getItem(Storage.SETTING_COLLECTIONS);
    let collections;
    if (collectionsString) {
      try {
        collections = JSON.parse(collectionsString);
      } catch (error) {
        console.warn('[StorageService] addDeviceSetting - parse - error:', error);
      }
    } else {
      collections = [];
    }
    collections.push(settings);
    localStorage.setItem(Storage.SETTING_COLLECTIONS, JSON.stringify(collections));
  }

  getDeviceSettings() {
    const collectionsString = localStorage.getItem(Storage.SETTING_COLLECTIONS);
    let collections = [];
    if (collectionsString) {
      try {
        collections = JSON.parse(collectionsString);
        collections.forEach((item: SettingsPersistPackage) => {
          if (item?.json && typeof item.json === 'string') {
            item.json = JSON.parse(item.json);
          }
        });
      } catch (error) {
        console.warn('[StorageService] getDeviceSettings - parse - error:', error);
      }
    }

    return collections as SettingsPersistPackage[];
  }

  getDeviceSettingById(id: string) {
    const settings = this.getDeviceSettings();
    return settings.find(item => item.id === id);
  }

  deleteDeviceSettingById(id: string) {
    const settings = this.getDeviceSettings();
    const newSettings: SettingsPersistPackage[] = [];
    settings.forEach(item => {
      if (item.id !== id) {
        newSettings.push(item);
      }
    });
    localStorage.setItem(Storage.SETTING_COLLECTIONS, JSON.stringify(newSettings));

    return newSettings;
  }
}

export enum Selector {
  NOT_AVAILABLE = 'notAvailable'
}

export enum SelectorSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  FULL = 'full'
}

export enum Storage {
  LANGUAGE = 'language',
  SETTING_COLLECTIONS = 'settingCollections'
}

export enum FlashEvent {
  READ_ERROR = 'readError',
  NO_FILE_SELECTED = 'noFileSelected',
  NO_BLE_CONNECTION = 'noBleConnection',
  FLASH_SUCCESS = 'flashSuccess',
  FLASH_FAILURE = 'flashFailure',
  FLASH_ERROR_FIRST_BYTES = 'flashErrorFirstBytes',
  FLASH_ERROR_LAST_BYTES = 'flashErrorLastBytes',
  FLASH_ENDED = 'flashEnded'
}

export enum DoorMovement {
  END_POSITION_OPEN_INSIDE,
  END_POSITION_CLOSE,
  IME_POSITION_OPEN,
  IME_POSITION_CLOSE,
  POSITION_STOP,
  IME_SP_LS_POINT,
  IME_SP_DEACTIVATION_POINT_CLOSE,
  IME_SP_DEACTIVATION_POINT_OPEN,
  IME_SP_SAFETY_EDGE_TEST_POINT,
  END_POSITION_OPEN_OUTSIDE
}

export enum DoorPosition {
  UNKNOWN_INVALID,
  UNTRAINED_END_POSITION,
  OVER_END_POSITION_OPEN,
  END_POSITION_OPEN,
  INTERMEDIATE_OPEN,
  BETWEEN_END_POSITIONS,
  BETWEEN_EP_CLOSE_AND_IME_OPEN,
  BETWEEN_EP_OPEN_AND_IME_CLOSE,
  BETWEEN_EP_CLOSE_AND_IME_CLOSE,
  BETWEEN_EP_OPEN_AND_IME_OPEN,
  BETWEEN_IME_OPEN_AND_IME_CLOSE,
  INTERMEDIATE_CLOSE,
  END_POSITION_CLOSE,
  OVER_END_POSITION_CLOSE
}

export enum DoorState {
  UNKNOWN = 'unknown',
  OPEN = 'door-open',
  CLOSED = 'door-closed',
  INTERMEDIATE_OPEN = 'door-imo',
  INTERMEDIATE_CLOSED = 'door-imc'
}

export enum Action {
  EXECUTE = 'execute',
  ADD = 'add',
  DELETE = 'delete',
  SET = 'set',
  DISABLE = 'disable'
}

export enum BroadcastCategory {
  INFORMATION = 'information',
  ADDITIONAL_FUNCTIONS = 'additionalFunctions',
  ERROR_STATUS = 'errorStatus',
  PROGRAMMABLE_INPUT = 'programmableInput',
  PROGRAMMABLE_OUTPUT = 'programmableOutput',
  ERROR_LOG = 'errorLog',
  GENERAL_SETTINGS = 'generalSettings',
  MAINTENANCE = 'maintenance',
  INSTALLATION = 'installation',
  RADIO_SETTINGS = 'radioSettings',
  SETTINGS_COLLECTION = 'settingsCollection'
}

export enum RadioLearning {
  CLOSE           = 8,
  INSIDE_TRAFFIC  = 16,
  OUTSIDE_TRAFFIC = 17,
  OPEN            = 22,
  STOP            = 36,
  IME_OPEN        = 51,
  IME_CLOSE       = 50,
  IMPULSE         = 86,
  PROG_OUT_1      = 153
}

export enum RadioLearnState {
  NOT_LEARNING,
  LEARNING,
  LEARNING_SUCCESS,
  LEARNING_FAILURE
}

export enum InteractionType {
  UNKNOWN = 'unknown',
  BUTTON_PRESSED = 'buttonPressed',
  BUTTON_RELEASED = 'buttonReleased'
}

export enum LOCALE {
  GERMAN = 'de',
  ENGLISH = 'en',
  SPANISH = 'es',
  FRENCH = 'fr',
  ITALIAN = 'it',
  DUTCH = 'nl'
}

// MCP enums
export enum DoorLearnStateEnum {
  _unknown = -1,
  learned,
  learning,
  unlearned,
  imeLearning,
  runInNeeded
}

export enum EndPositionTypeEnum {
  _unknown = -1,
  awg,
  mechanicalEndSwitches,
  autolearnEndPositionType
}

export enum RotatingFieldEnum {
  _unknown = -1,
  default,
  reversedRotating
}

export enum LearningCommandEnum {
  _unknown = -1,
  requestLearning,
  saveOpen,
  saveClose,
  skipOpen,
  skipClose,
  abortLearning,
  driveOpen,
  driveClose,
  driveStop
}

export enum LearningImePositionEnum {
  _unknown = -1,
  requestLearning,
  abortLearning,
  saveImeOpen,
  saveImeClose,
  spDeactivationOpen,
  spDeactivationClose,
  spLsPoint,
  spSafetyEdgeTestPoint,
  stopDriving,
  openDriving,
  closeDriving
}

export enum DeleteImePositionEnum {
  _unknown = -1,
  allImePositions,
  allSpPositions,
  allPositions,
  imePositionOpen,
  imePositionClose,
  spDeactivationOpen,
  spDeactivationClose,
  spLsPoint,
  spSafetyEdgeTestPoint
}

export enum ImpulsTargetEnum {
  _unknown = -1,
  endPositionOpen,
  endPositionClose,
  imePositionOpen,
  imePositionClose,
  positionStop,
  posSpLsPoint,
  posSpDeactivationClose,
  posSpDeactivationOpen,
  posSpSafetyEdgeTestPoint
}

export enum MotorStateEnum {
  _unknown = -1,
  notInstalled,
  stop,
  close,
  open
}

export enum CurrentDoorPositionEnum {
  _unknown = -1,
  unknownInvalid,
  untrainedEndPosition,
  overEndPosition,
  endPositionOpen,
  intermediateOpen,
  betweenEndPositions,
  betweenEpCloseAndImeOpen,
  betweenEpOpenAndImeClose,
  betweenEpCloseAndImeClose,
  betweenEpOpenAndImeOpen,
  betweenImeOpenAndImeClose,
  intermediateClose,
  endPositionClose,
  overEndPositionClose
}

export enum Color {
  ALARM = '#ff0000',
  DEFAULT = '#aaaaaa'
}

import { Injectable } from '@angular/core';
import { AbstractMcpDeviceService } from '@app/core/services';
import { CommId } from '@app/core/services/broadcast.service';
import { DoorPosition, DoorState } from '@app/shared/enums/enums';
import { RegisterService } from '@app/shared/services/register.service';
import { BehaviorSubject } from 'rxjs';

export type RemoteControlStatesKV = {
  [key in CommId]?: number | number[];
}
@Injectable({
  providedIn: 'root'
})
export class RemoteControlService extends RegisterService {
  updateOnDoorPercent: BehaviorSubject<number[] | undefined> = new BehaviorSubject<
    number[] | undefined
  >(undefined);
  updateOnDoorPosition: BehaviorSubject<DoorState> = new BehaviorSubject<
    DoorState
  >(DoorState.INTERMEDIATE_CLOSED);
  override serviceId = 'RemoteControl';
  remoteControlStates: RemoteControlStatesKV = {
    [CommId.ID_SP_DEACTIVATION_CLOSE]: 0,
    [CommId.ID_SP_DEACTIVATION_OPEN]: 0,
    [CommId.ID_LS_POINT]: 0,
    [CommId.ID_SAFETY_EDGE_TEST_POINT]: 0,
    [CommId.ID_INTERMEDIATE_POSITIONS]: [0, 0]
  }
  updateOnRemoteControlStates: BehaviorSubject<RemoteControlStatesKV> = new BehaviorSubject<
    RemoteControlStatesKV
  >(this.remoteControlStates);

  constructor(private readonly _mcpDeviceService: AbstractMcpDeviceService) {
    super();
    this.init();
  }

  override async init() {
    super.init();

    const singleValueObservableIds = [
      CommId.ID_SP_DEACTIVATION_CLOSE,
      CommId.ID_SP_DEACTIVATION_OPEN,
      CommId.ID_LS_POINT,
      CommId.ID_SAFETY_EDGE_TEST_POINT
    ];
    singleValueObservableIds.forEach(commId => {
      const id = commId as number;
      const observable = this._mcpDeviceService.getBroadcastById(id)?.observable;
      if (observable) {
        observable.subscribe((result) => {
          const values = result as number[];
          if (Array.isArray(values) && values.length > 0) {
            this.remoteControlStates[commId] = values[0];
            this.updateOnRemoteControlStates.next(this.remoteControlStates);
          }
        });
      }
    });
    
    const observableIME = this._mcpDeviceService.getBroadcastById(CommId.ID_INTERMEDIATE_POSITIONS)?.observable;
    if (observableIME) {
      observableIME.subscribe((result) => {
        const values = result as number[];
        if (Array.isArray(values) && values.length > 1) {
          this.remoteControlStates[CommId.ID_INTERMEDIATE_POSITIONS] = values;
          this.updateOnRemoteControlStates.next(this.remoteControlStates);
        }
      });
    }

    const observableDoorPercent = this._mcpDeviceService.getBroadcastById(CommId.ID_CURRENT_DOOR_POSITION_IN_PERCENT)?.observable;
    if (observableDoorPercent) {
      observableDoorPercent.subscribe(value => {
        const percentages = value as unknown as number[];
        this.updateOnDoorPercent.next(percentages);
      });
    }

    const observableDoor = this._mcpDeviceService.getBroadcastById(CommId.ID_CURRENT_DOOR_POSITION)?.observable;
    if (observableDoor) {
      observableDoor.subscribe(value => {
        const stateIndex = Array.isArray(value) ? value[0] : value;
        let doorPosition = DoorState.UNKNOWN;
        switch(stateIndex) {
          case DoorPosition.UNKNOWN_INVALID:
          case DoorPosition.UNTRAINED_END_POSITION:
            doorPosition = DoorState.UNKNOWN;
            break;
          case DoorPosition.OVER_END_POSITION_OPEN:
          case DoorPosition.END_POSITION_OPEN:
            doorPosition = DoorState.OPEN;
            break;
          case DoorPosition.INTERMEDIATE_OPEN:
          case DoorPosition.BETWEEN_END_POSITIONS:
          case DoorPosition.BETWEEN_EP_CLOSE_AND_IME_OPEN:
          case DoorPosition.BETWEEN_EP_OPEN_AND_IME_CLOSE:
          case DoorPosition.BETWEEN_EP_OPEN_AND_IME_OPEN:
            doorPosition = DoorState.INTERMEDIATE_OPEN;
            break;
          case DoorPosition.BETWEEN_IME_OPEN_AND_IME_CLOSE:
          case DoorPosition.BETWEEN_EP_CLOSE_AND_IME_CLOSE:
            doorPosition = DoorState.INTERMEDIATE_CLOSED;
            break;
          case DoorPosition.END_POSITION_CLOSE:
          case DoorPosition.OVER_END_POSITION_CLOSE:
            doorPosition = DoorState.CLOSED;
            break;
          default:
            doorPosition = DoorState.UNKNOWN;
        }
        this.updateOnDoorPosition.next(doorPosition);
      });
    }
  }
}

import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { StorageService } from './storage.service';
import { Language } from '../interfaces/interfaces';
import { DEFAULT_LANG } from '@app/core/transloco-root.module';

export const REMOTE_LANG_PREFIX = 'mcp_';
export const I18N_PREFIX = 'i18n_';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  constructor(
    private readonly _translocoService: TranslocoService,
    private readonly _storageService: StorageService
  ) {}

  public init() {
    this._translocoService.setDefaultLang(DEFAULT_LANG);

    // is a language selection persisted in local storage
    const language = this._storageService.getLanguage();
    if (language) {
      this._translocoService.setActiveLang(language.locale);
    } else {
      this._translocoService.setActiveLang(this.getUsersLanguage());
    }
  }

  public setLanguage(language: Language) { // e.g.: i18n_en
    this._storageService.setLanguage(language);
    this._translocoService.setActiveLang(language.locale);
  }

  public getLanguage() {
    return this._translocoService.getActiveLang();
  }

  private getUsersLanguage(): string {
    const browserLanguage = navigator?.language?.split('-')[0] as any;
    return this._translocoService.getAvailableLangs().includes(browserLanguage)
      ? browserLanguage
      : this._translocoService.getDefaultLang();
  }
}

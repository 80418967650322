import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

@Component({
    selector: 'mcp-upload-cloud-button',
    templateUrl: 'upload-cloud-button.component.html',
    styleUrls: ['upload-cloud-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class UploadCloudButtonComponent {
  @Input() public loading: boolean = false;
  @Input() public disabled: boolean = true;

  @Output() public buttonClicked: EventEmitter<void> = new EventEmitter<void>();

  public onClick(): void {
    if (this.loading || this.disabled) {
      return;
    }

    this.buttonClicked.emit();
  }
}

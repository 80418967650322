<div class="">
  <div class="form-group">
    <ng-container *ngFor="let item of items; let index">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" name="check" value="{{item.id}}" (click)="onClick(item)" [disabled]="isDisabled">
        <label class="form-check-label" for="check-{{index}}">
          {{ item.label | transloco }}
        </label>
      </div>
    </ng-container>
  </div>
</div>
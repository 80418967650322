/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable object-shorthand */

import { Injectable } from '@angular/core';
import {
  AbstractMcpDeviceCommService,
  McpDeviceCommStateEnum
} from './abstract-mcp-device-comm.service';
import { McpDeviceError } from './abstract-mcp-device.service';
import { ResponseCallbackRequest, WriteRequest } from '@app/shared/interfaces/interfaces';
import { Subject } from 'rxjs';

declare const navigator: any;

// TODO: for error testing only, REMOVEME
declare let ERRORTEST_READ: number;
declare let ERRORTEST_WRITE: number;

@Injectable()
export class McpDeviceCommService implements AbstractMcpDeviceCommService {
  static readonly MCP_BLE_DEVICE_NAME = 'MCP-Control';
  static readonly MCP_BLE_SERVICE_UUID = 'b19a0000-cc9e-488d-9744-a1e6525caad5';
  static readonly MCP_BLE_CHARACTERISTIC_WRITE_UUID =
    'b19a02aa-cc9e-488d-9744-a1e6525caad5';

  static readonly MCP_BLE_CHARACTERISTIC_NOTIFY_UUID =
    'b19a04aa-cc9e-488d-9744-a1e6525caad5';

  static readonly MCP_BLE_SW_UPDATE_SERVICE_UUID =
    'c19a0000-cc9e-488d-9744-a1e6525caad5';

  static readonly MCP_BLE_SW_UPDATE_CHARACTERISTIC_WRITE_UUID =
    'c19a02aa-cc9e-488d-9744-a1e6525caad5';

  static readonly MCP_BLE_PROTOCOL_CHUNK_SIZE = 20;
  static readonly MCP_BLE_PROTOCOL_SINGLE_FRAME_MAX_PAYLOAD_SIZE =
    McpDeviceCommService.MCP_BLE_PROTOCOL_CHUNK_SIZE - 2;

  static readonly MCP_BLE_PROTOCOL_MULTI_FIRST_FRAME_MAX_PAYLOAD_SIZE =
    McpDeviceCommService.MCP_BLE_PROTOCOL_CHUNK_SIZE - 3;

  static readonly MCP_BLE_PROTOCOL_MULTI_SUBSEQUENT_FRAME_MAX_PAYLOAD_SIZE =
    McpDeviceCommService.MCP_BLE_PROTOCOL_CHUNK_SIZE - 1;

  static readonly MCP_BLE_PROTOCOL_DEFAULT_RESPONSE_TIMEOUT_MS = 5000;
   public readonly updateOnError: Subject<ResponseCallbackRequest> =
        new Subject<ResponseCallbackRequest>();

  private _connected = false;
  private _connecting = false;
  private _on_debug_log: (arg0: string) => undefined | undefined;
  private _on_receive_update: (json: any) => undefined | undefined;
  private _on_receive_response: (json: any) => undefined | undefined;
  private _on_connection_lost: (() => undefined) | undefined;
  private _device: any;
  private _service: any;
  private _characteristic_notify: any;
  private _characteristic_write: any;
  private readonly _textencoder = new TextEncoder();
  private readonly _textdecoder = new TextDecoder('utf-8');
  private _default_response_timeout_ms =
    McpDeviceCommService.MCP_BLE_PROTOCOL_DEFAULT_RESPONSE_TIMEOUT_MS;

  //
  // API
  //

  // check if browser is compatible with WebBT (depending on the bwrowser WebBT might need to be enabled first, and page must be served by a secure context)
  // returns:
  //   compatibility (true:compatible, false: incompatible)
  isBrowserCompatible(): boolean {
    if (navigator.bluetooth) {
      return true;
    } else {
      return false;
    }
  }

  // connect to device and register callbacks
  // arguments:
  //   onReceiveUpdate(json): optional callback to call when a previously registered ID receives an update (json: received json object from device)
  //   onConnectionLost(): optional callback to call upon unexpected connection loss (will only be called when we were actually connected in the first place)
  // returns:
  //   connect promise with empty result
  async connect(onReceiveUpdate?: any, onConnectionLost?: any): Promise<any> {
    if (this._connecting) {
      throw new McpDeviceError(
        'Connect operation already running',
        McpDeviceError.ERROR_MCP_DEVICE_CONNECT_FAILED
      );
    }
    this.disconnect();
    this.resetInternalState();

    this.debugLog('Requesting Bluetooth Device...');
    this._connecting = true;
    return navigator.bluetooth
      .requestDevice({
        filters: [
          {
            name: McpDeviceCommService.MCP_BLE_DEVICE_NAME
          }
        ],
        optionalServices: [
          McpDeviceCommService.MCP_BLE_SERVICE_UUID,
          McpDeviceCommService.MCP_BLE_SW_UPDATE_SERVICE_UUID
        ]
      })
      .then((device: { gatt: { connect: () => any } }) => {
        this._device = device;
        this.debugLog('Connecting to GATT Server...');
        return device.gatt.connect();
      })
      .then((server: { getPrimaryService: (arg0: string) => any }) => {
        this.debugLog('Getting Service...');
        return server.getPrimaryService(
          McpDeviceCommService.MCP_BLE_SERVICE_UUID
        );
      })
      .then((service: { getCharacteristic: (arg0: string) => any }) => {
        this._service = service;
        this.debugLog('Getting Notify Characteristic...');
        return service.getCharacteristic(
          McpDeviceCommService.MCP_BLE_CHARACTERISTIC_NOTIFY_UUID
        );
      })
      .then((characteristic: any) => {
        this._characteristic_notify = characteristic;

        this.debugLog('Starting Notifications...');
        return this._characteristic_notify.startNotifications();
      })
      .then(() => {
        this._characteristic_notify.addEventListener(
          'characteristicvaluechanged',
          this.handleNotifyValueChanged.bind(this)
        );

        this.debugLog('Getting Write Characteristic...');
        return this._service.getCharacteristic(
          McpDeviceCommService.MCP_BLE_CHARACTERISTIC_WRITE_UUID
        );
      })
      .then((characteristic: any) => {
        this._characteristic_write = characteristic;
        this._on_receive_update = onReceiveUpdate;
        this._on_connection_lost = onConnectionLost;
        this._device.addEventListener(
          'gattserverdisconnected',
          this.handleConnectionLost.bind(this)
        );
        this._connected = true;
      })
      .catch((error: any) => {
        if (!(error instanceof McpDeviceError)) {
          error = new McpDeviceError(
            error.message,
            McpDeviceError.ERROR_MCP_DEVICE_CONNECT_FAILED
          );
        }
        throw error;
      })
      .finally(() => {
        this._connecting = false;
      });
  }

  // disconnect from device and unregister callbacks
  // returns:
  //   disconnect promise with empty result
  async disconnect(): Promise<any> {
    if (!this.isConnected()) {
      return;
    }

    this.debugLog('Stopping notifications...');
    return this._characteristic_notify
      .stopNotifications()
      .then(() => {
        this._characteristic_notify.removeEventListener(
          'characteristicvaluechanged',
          this.handleNotifyValueChanged.bind(this)
        );
        this._device.removeEventListener(
          'gattserverdisconnected',
          this.handleConnectionLost.bind(this)
        );
        this._on_connection_lost = undefined;
        this.debugLog('Disconnecting...');
        return this._device.gatt.disconnect();
      })
      .then(() => {
        this._connected = false;
      })
      .catch((error: any) => {
        if (!(error instanceof McpDeviceError)) {
          error = new McpDeviceError(
            error.message,
            McpDeviceError.ERROR_UNKNOWN
          );
        }
        throw error;
      });
  }

  // obtains the current state of the stack
  // returns:
  //   state (STATE_DISCONNECTED, STATE_CONNECTING, STATE_CONNECTED_IDLE or STATE_CONNECTED_BUSY)
  getState(): McpDeviceCommStateEnum {
    if (!this.isConnected()) {
      return this._connecting
        ? McpDeviceCommStateEnum.STATE_CONNECTING
        : McpDeviceCommStateEnum.STATE_DISCONNECTED;
    } else {
      return this.isIdle()
        ? McpDeviceCommStateEnum.STATE_CONNECTED_IDLE
        : McpDeviceCommStateEnum.STATE_CONNECTED_BUSY;
    }
  }

  // check if connection to device is established
  // returns:
  //   connection state (true:connected, false: disconnected)
  isConnected(): boolean {
    return this._connected;
  }

  // check if stack is idle or currently processing a request (if the connection is not established, it will return false as well)
  // returns:
  //   idle state (true:idle, false: processing/not connected)
  isIdle(): boolean {
    return this.isConnected() && !this._request_response_active;
  }

  // obtains the connected device id
  // returns:
  //   id if connected (else empty string)
  getDeviceId(): string {
    if (this.isConnected()) {
      return this._device.id;
    } else {
      return '';
    }
  }

  // obtains the connected device name
  // returns:
  //   name if connected (else empty string)
  getDeviceName(): string {
    if (this.isConnected()) {
      return this._device.name;
    } else {
      return '';
    }
  }

  getDevice(): any {
    if (this.isConnected()) {
      return this._device;
    } else {
      return null;
    }
  }

  // set/clear debug log callback
  // arguments:
  //   onDebugLog(message): optional callback to call upon any debug output
  setDebugLogCallback(onDebugLog?: any): void {
    this._on_debug_log = onDebugLog;
  }

  // set/clear received response callback
  // arguments:
  //   onReceiveResponse(json): optional callback to call upon receiving any json object from device
  setReceiveResponseCallback(onReceiveResponse?: any): void {
    this._on_receive_response = onReceiveResponse;
  }

  // set/reset default response timeout
  // arguments:
  //   defaultTimeoutMs: optional timeout in ms to wait for a response after sending a request (else request will fail with a timeout error)
  setDefaultResponseTimeout(defaultTimeoutMs?: number): void {
    this._default_response_timeout_ms =
      defaultTimeoutMs ??
      McpDeviceCommService.MCP_BLE_PROTOCOL_DEFAULT_RESPONSE_TIMEOUT_MS;
  }

  // Read id from device
  // arguments:
  //   id: numerical ID to read
  //   responseTimeoutMs: optional response timeout
  // returns:
  //   read id promise with array of the received values as result
  async readId(id: number, responseTimeoutMs?: number): Promise<any[]> {
    const request = { cmd: 'r', id: id };

    return await this.buildRequestResponsePromise(
      request,
      id,
      (result: { d: string | any[] }) => {
        const values = [];
        for (let n = 0; n < result.d.length; n++) {
          values.push(result.d[n]);
        }
        return values;
      },
      responseTimeoutMs
    );
  }

  // Write id to device
  // arguments:
  //   id: numerical ID to write
  //   values: array of values to write
  //   responseTimeoutMs: optional response timeout
  // returns:
  //   write id promise with empty result
  async writeId(
    id: any,
    values: number | number[],
    responseTimeoutMs?: number
  ): Promise<any> {
    const request: WriteRequest = {
      cmd: 'w',
      id
    };

    if (Array.isArray(values) || typeof values === 'number') {
      request['d'] = Array.isArray(values) ? values : [values]
    }

    return await this.buildRequestResponsePromise(
      request,
      id,
      (result: any) => {},
      responseTimeoutMs
    );
  }

  // Register id(s) to get automatic updates for from device
  // arguments:
  //   ids: array of numerical IDs to register
  //   responseTimeoutMs: optional response timeout
  // returns:
  //   register id promise with empty result
  async registerId(
    ids: number | number[],
    responseTimeoutMs?: number
  ): Promise<any> {
    const identifiers = Array.isArray(ids) ? ids : [ids]
    if (identifiers.indexOf(0) > -1) {
      console.warn('There is an id of 0 at ' + identifiers.indexOf(0));
    }
    const request = { cmd: 'b', id_n: identifiers };

    return await this.buildRequestResponsePromise(
      request,
      0,
      (result: any) => {},
      responseTimeoutMs
    );
  }

  // Unregister id(s) to get automatic updates for from device
  // arguments:
  //   ids: array of numerical IDs to unregister
  //   responseTimeoutMs: optional response timeout
  // returns:
  //   unregister id promise with empty result
  async unregisterId(
    ids: number | number[],
    responseTimeoutMs?: number
  ): Promise<any> {
    const request = { cmd: 'c', id_n: Array.isArray(ids) ? ids : [ids] };

    return await this.buildRequestResponsePromise(
      request,
      0,
      (result: any) => {},
      responseTimeoutMs
    );
  }

  // Get all available ids from device
  // arguments:
  //   responseTimeoutMs: optional response timeout
  // returns:
  //   get all ids promise with array of the received IDs as result
  async getAllIds(responseTimeoutMs?: number): Promise<any[]> {
    const request = { cmd: 'a' };

    return await this.buildRequestResponsePromise(
      request,
      undefined,
      (result: any) => result.id_n,
      responseTimeoutMs
    );
  }

  // Get id structure from device
  // arguments:
  //   id: numerical ID to get structure of
  //   responseTimeoutMs: optional response timeout
  // returns:
  //   get id structure promise with structure json object as result
  async getIdStructure(id: number, responseTimeoutMs?: number): Promise<any> {
    const request = { cmd: 'j', id: id };

    return await this.buildRequestResponsePromise(
      request,
      id,
      (result: any) => {},
      responseTimeoutMs
    );
  }

  //
  // Internal functions
  //

  private debugLog(...args: unknown[]): void {
    if (!this._on_debug_log) {
      return;
    }

    const line = Array.prototype.slice
      .call(args)
      .map(function (argument) {
        return typeof argument === 'string'
          ? argument
          : JSON.stringify(argument);
      })
      .join(' ');

    this._on_debug_log(line);
  }

  private handleConnectionLost(): void {
    this._connected = false;
    if (this._on_connection_lost) {
      this._on_connection_lost();
    }
  }

  private handleNotifyValueChanged(event: any): void {
    this.receiveRawData(new Uint8Array(event.target.value.buffer));
  }

  private async sendRequest(json: any): Promise<void> {
    this.debugLog('Sending request', json);

    return await this.sendRawData(
      this._textencoder.encode(JSON.stringify(json))
    );
  }

  private async buildRequestResponsePromise(
    request: any,
    expectedResponseId: number | undefined,
    successHandler: any,
    responseTimeoutMs?: number
  ): Promise<any> {
    if (!this.isConnected()) {
      throw new McpDeviceError(
        'Not connected',
        McpDeviceError.ERROR_MCP_DEVICE_NOT_CONNECTED
      );
    }

    if (!responseTimeoutMs) {
      responseTimeoutMs = this._default_response_timeout_ms;
    }

    let promise;
    if (!this.isIdle()) {
      // we are not idle currently, create promise to wait until it's our turn before sending the request (as MCP doesn't support parallel requests)
      const isIdlePromise = new Promise((resolve) => {
        this._request_response_isidlepromise_resolve.push(resolve);
        this.debugLog('Queueing request', request);
      });

      promise = isIdlePromise.then(async () => {
        // we have to make sure response callback is already in place when sendRequest() returns,
        // else we risk a race condition when the MCP answer is quicker than we can create the responseCallbackPromise
        const responseCallbackPromise = new Promise((resolve, reject) => {
          // provide callback (and timeout) for response
          const timeoutId = setTimeout(() => {
            console.log('Before timeout error - id: ', this._request_response_callback);
            this.updateOnError.next(this._request_response_callback);
            this._request_response_callback = {};
            reject(
              new McpDeviceError(
                'Timeout waiting for request response',
                McpDeviceError.ERROR_MCP_DEVICE_COMM_TIMEOUT
              )
            );
          }, responseTimeoutMs);
          this._request_response_callback = {
            cmd: request.cmd,
            id: expectedResponseId,
            callback: resolve,
            timeoutId: timeoutId
          };
        });

        await this.sendRequest(request);
        return await responseCallbackPromise;
      });
    } else {
      // we have to make sure response callback is already in place when sendRequest() returns,
      // else we risk a race condition when the MCP answer is quicker than we can create the responseCallbackPromise
      const responseCallbackPromise = new Promise((resolve, reject) => {
        // provide callback (and timeout) for response
        const timeoutId = setTimeout(() => {
          console.log('Before timeout error - id 2: ', this._request_response_callback);
          this.updateOnError.next(this._request_response_callback);
          this._request_response_callback = {};
          reject(
            new McpDeviceError(
              'Timeout waiting for request response',
              McpDeviceError.ERROR_MCP_DEVICE_COMM_TIMEOUT
            )
          );
        }, responseTimeoutMs);
        this._request_response_callback = {
          cmd: request.cmd,
          id: expectedResponseId,
          callback: resolve,
          timeoutId: timeoutId
        };
      });

      promise = this.sendRequest(request).then(async () => {
        return await responseCallbackPromise;
      });
    }

    this._request_response_active = true;

    return await promise
      .then(async (result: any) => {
        // evaluate response
        return await new Promise((resolve, reject) => {
          // TODO: added check for nrc property
          // investigate why this call always expects this property
          if (
            !Object.prototype.hasOwnProperty.call(result, 'nrc') ||
            result.nrc === 0 ||
            result.nrc === 'NULL'
          ) {
            resolve(successHandler(result));
          } else {
            reject(
              new McpDeviceError(
                'Request rejected with error ' + String(result.nrc),
                McpDeviceError.BASE_MCP_DEVICE + Number(result.nrc)
              )
            );
          }
        });
      })
      .finally(() => {
        // make sure response callback is removed and timer stopped in case of any error
        if (this._request_response_callback.timeoutId !== undefined) {
          clearTimeout(this._request_response_callback.timeoutId);
        }
        this._request_response_callback = {};

        // are other commands waiting for idle?
        if (this._request_response_isidlepromise_resolve.length > 0) {
          // yes, launch the next one
          // this.debugLog('Triggering next request...');
          const isIdlePromiseResolve =
            this._request_response_isidlepromise_resolve.shift();
          isIdlePromiseResolve();
        } else {
          // no more commands queued, we are idle again
          this._request_response_active = false;
        }
      });
  }

  async sendRawData(data: Uint8Array): Promise<void> {
    const frames = [];

    if (
      data.length <=
      McpDeviceCommService.MCP_BLE_PROTOCOL_SINGLE_FRAME_MAX_PAYLOAD_SIZE
    ) {
      // debugLog('Sending data as single frame...');
      frames.push(
        new Uint8Array([...Uint8Array.of(0x00, data.length), ...data])
      );
    } else {
      // debugLog('Sending data as multi frame...');

      // first frame
      let start_idx = 0;
      const frame = new Uint8Array([
        ...Uint8Array.of(0x10, data.length >> 8, data.length),
        ...data.slice(
          start_idx,
          start_idx +
            McpDeviceCommService.MCP_BLE_PROTOCOL_MULTI_FIRST_FRAME_MAX_PAYLOAD_SIZE
        )
      ]);
      frames.push(frame);
      start_idx +=
        McpDeviceCommService.MCP_BLE_PROTOCOL_MULTI_FIRST_FRAME_MAX_PAYLOAD_SIZE;

      // subsequent frame(s)
      let frame_control = 0x20;
      while (start_idx < data.length) {
        const frame = new Uint8Array([
          ...Uint8Array.of(frame_control),
          ...data.slice(
            start_idx,
            start_idx +
              McpDeviceCommService.MCP_BLE_PROTOCOL_MULTI_SUBSEQUENT_FRAME_MAX_PAYLOAD_SIZE
          )
        ]);
        frames.push(frame);
        start_idx +=
          McpDeviceCommService.MCP_BLE_PROTOCOL_MULTI_SUBSEQUENT_FRAME_MAX_PAYLOAD_SIZE;

        frame_control++;
        if (frame_control > 0x2f) {
          frame_control = 0x20;
        }
      }
    }

    return await frames.reduce(async (promise, frame) => {
      await promise;
      return this._characteristic_write.writeValue(frame);
    }, Promise.resolve());
  }

  private _receiving_multi_frame: boolean;
  private _expected_frame_control: number;
  private _expected_data_length: number;
  private _received_data: Uint8Array;
  private _request_response_active: boolean;
  private _request_response_callback: ResponseCallbackRequest;
  private _request_response_isidlepromise_resolve: any;

  private resetInternalState(): void {
    this._receiving_multi_frame = false;
    this._request_response_active = false;
    this._request_response_callback = {};
    this._request_response_isidlepromise_resolve = [];
  }

  private receiveRawData(data: Uint8Array): void {
    if (data.length < 1) {
      this.debugLog('Error: Data length too short to read frame control');
      return;
    }

    const received_frame_control = data[0];
    if (received_frame_control === 0x00) {
      // single frame
      // debugLog('Receiving single frame...');
      if (this._receiving_multi_frame) {
        this.debugLog('Warning: Previous multi frame receive was not finished');
      }
      this._receiving_multi_frame = false;
      if (data.length < 2) {
        this.debugLog('Error: Data length too short to read data size');
        return;
      }
      if (data.length !== data[1] + 2) {
        this.debugLog(
          'Error: Data length mismatch (received:' +
            data.length.toString() +
            ', expected:' +
            (data[1] + 2).toString() +
            ')'
        );
      }
    } else {
      if (received_frame_control === 0x10) {
        // debugLog('Receiving multi frame start...');
        if (this._receiving_multi_frame) {
          this.debugLog(
            'Warning: Previous multi frame receive was not finished'
          );
        }
        if (data.length < 3) {
          this.debugLog('Error: Data length too short to read data size');
          return;
        }
        this._receiving_multi_frame = true;
        this._expected_data_length = (data[1] << 8) + data[2];
        this._received_data = data.slice(3);
        this._expected_frame_control = 0x20;
      } else if (
        this._receiving_multi_frame &&
        received_frame_control === this._expected_frame_control
      ) {
        // debugLog('Receiving multi frame...');
        this._received_data = new Uint8Array([
          ...this._received_data,
          ...data.slice(1)
        ]);
        if (this._received_data.length === this._expected_data_length) {
          // debugLog('Received last multi frame...');
          this._receiving_multi_frame = false;

          let json;
          try {
            json = JSON.parse(this._textdecoder.decode(this._received_data));
          } catch (error) {
            this.debugLog(
              'Error: Unable to parse JSON (received:' +
                this._textdecoder.decode(this._received_data) +
                '): ' +
                String(error)
            );
            this._receiving_multi_frame = false;
            return;
          }

          // is some previously issued request waiting for this response?
          if (
            this._request_response_callback.cmd === json.res &&
            this._request_response_callback.id === json.id
          ) {
            // yes, remove and call the request callback to evaluate the response
            const cb = this._request_response_callback;
            this._request_response_callback = {};
            clearTimeout(cb.timeoutId);

            // TODO: for error testing only, REMOVEME
            if (
              json.res === 'r' &&
              typeof ERRORTEST_READ !== 'undefined' &&
              ERRORTEST_READ > 0
            ) {
              console.warn(
                'ERRORTEST_READ is ' +
                  String(ERRORTEST_READ) +
                  ', simulating read fail and decreasing variable'
              );
              ERRORTEST_READ--;
              json.nrc = 32; // ERROR_CBUS_READ_SEND
            } else if (
              json.res === 'w' &&
              typeof ERRORTEST_WRITE !== 'undefined' &&
              ERRORTEST_WRITE > 0
            ) {
              console.warn(
                'ERRORTEST_WRITE is ' +
                  String(ERRORTEST_WRITE) +
                  ', simulating write fail and decreasing variable'
              );
              ERRORTEST_WRITE--;
              json.nrc = 31; // ERROR_CBUS_WRITE_SEND
            }

            cb.callback(json);
          }

          if (this._on_receive_update && json.res === 'u') {
            this._on_receive_update(json);
          }
          if (this._on_receive_response) {
            this._on_receive_response(json);
          }
        } else if (this._received_data.length > this._expected_data_length) {
          this.debugLog(
            'Error: Data length overflow (received:' +
              this._received_data.length.toString() +
              ', expected:' +
              this._expected_data_length.toString() +
              ')'
          );
          this._receiving_multi_frame = false;
        } else {
          this._expected_frame_control++;
          if (this._expected_frame_control > 0x2f) {
            this._expected_frame_control = 0x20;
          }
        }
      } else {
        if (this._receiving_multi_frame) {
          this.debugLog(
            'Error: Unexpected frame control (received:' +
              received_frame_control.toString() +
              ', expected:' +
              this._expected_frame_control.toString() +
              ')'
          );
          this._receiving_multi_frame = false;
        } else {
          this.debugLog(
            'Error: Unexpected frame control while not receiving multi frame (received:' +
              received_frame_control.toString() +
              ')'
          );
        }
      }
    }
  }
}

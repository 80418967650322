import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { FormControl } from '@angular/forms';


@Component({
    selector: 'mcp-searchfield',
    templateUrl: 'searchfield.component.html',
    styleUrls: ['searchfield.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    standalone: false
})
export class SearchFieldComponent {
  @Input() description = '';
  @Output() changeEvent: EventEmitter<string | null> = new EventEmitter<string | null>();

  isDisabled = false;
  searchTerm = new FormControl<string>('');

  search() {
    this.changeEvent.emit(this.searchTerm.value);
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from '@app/shared/services/user.service';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root',
})
export class KCAuthGuard extends KeycloakAuthGuard {
  constructor(
    protected override readonly router: Router,
    protected readonly keycloak: KeycloakService,
    protected readonly _userService: UserService
  ) {
    super(router, keycloak);
  }

  async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let hasAccess = false;

    this.authenticated = this.keycloak.isLoggedIn();
    console.log('[KCAuthGuard] isAccessAllowed - authenticated: ', this.authenticated);
    if (!this.authenticated) {
      try {
        await this.keycloak.login();
      } catch (error) {
        console.log('[KcAuthGuard] isAccessAllowed - keycloak.login - error: ', error);
      }
    }
    //console.log('token: ', this.keycloak.getToken());
    const user = await this.keycloak.loadUserProfile();
    this._userService.user = user;

    // Get the roles required from the route.
    const requiredRoles = route.data['roles'];
    const kcRoles = this.keycloak.getUserRoles();
    if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
      hasAccess = true;
    } else {
      if (requiredRoles.some((role) => kcRoles.includes(role))) {
        hasAccess = true;
      }
    }

    return hasAccess;
  }

  override canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log('KcAuthGuard - canActivate - state: ', state);
    return this.isAccessAllowed(route, state);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(route, state);
  }

  async logout() {
    try {
      await this.keycloak.logout();
      console.log('[KCAuthGuard] logout - success');
    } catch (error) {
      console.log('[KCAuthGuard] logout - error: ', error);
    }
  }
}

/* eslint-disable @typescript-eslint/naming-convention */

import { Injectable } from '@angular/core';
import {
  AbstractMcpDeviceCommService,
  McpDeviceCommStateEnum
} from './abstract-mcp-device-comm.service';
import { Subject } from 'rxjs';
import { ResponseCallbackRequest } from '@app/shared/interfaces/interfaces';

@Injectable()
export class McpDeviceCommMockService implements AbstractMcpDeviceCommService {
  static readonly DEFAULT_RESPONSE_TIMEOUT_MS = 5000;

  private _on_debug_log: (arg0: string) => undefined | undefined;
  private readonly _on_receive_update: (json: any) => undefined | undefined;
  private _on_receive_response: (json: any) => undefined | undefined;
  private readonly _on_connection_lost: (() => undefined) | undefined;
   public readonly updateOnError: Subject<ResponseCallbackRequest> =
        new Subject<ResponseCallbackRequest>();

  private _default_response_timeout_ms =
    McpDeviceCommMockService.DEFAULT_RESPONSE_TIMEOUT_MS;

  isBrowserCompatible(): boolean {
    throw new Error('Method not implemented.');
  }

  async connect(onReceiveUpdate?: any, onConnectionLost?: any): Promise<any> {}

  async disconnect(): Promise<any> {
    throw new Error('Method not implemented.');
  }

  getState(): McpDeviceCommStateEnum {
    throw new Error('Method not implemented.');
  }

  isConnected(): boolean {
    throw new Error('Method not implemented.');
  }

  isIdle(): boolean {
    throw new Error('Method not implemented.');
  }

  getDeviceId(): string {
    throw new Error('Method not implemented.');
  }

  getDeviceName(): string {
    throw new Error('Method not implemented.');
  }

  setDebugLogCallback(onDebugLog?: any): void {
    this._on_debug_log = onDebugLog;
  }

  setReceiveResponseCallback(onReceiveResponse?: any): void {
    this._on_receive_response = onReceiveResponse;
  }

  setDefaultResponseTimeout(defaultTimeoutMs?: number): void {
    this._default_response_timeout_ms =
      defaultTimeoutMs ?? McpDeviceCommMockService.DEFAULT_RESPONSE_TIMEOUT_MS;
  }

  async readId(id: number, responseTimeoutMs?: number): Promise<any[]> {
    throw new Error('Method not implemented.');
  }

  async writeId(
    id: any,
    values: number | number[],
    responseTimeoutMs?: number
  ): Promise<any> {
    throw new Error('Method not implemented.');
  }

  async registerId(
    ids: number | number[],
    responseTimeoutMs?: number
  ): Promise<any> {
    throw new Error('Method not implemented.');
  }

  async unregisterId(
    ids: number | number[],
    responseTimeoutMs?: number
  ): Promise<any> {
    throw new Error('Method not implemented.');
  }

  async sendRawData(data: Uint8Array): Promise<void> {
    throw new Error('Method not implemented.');
  }

  async getAllIds(responseTimeoutMs?: number): Promise<any[]> {
    throw new Error('Method not implemented.');
  }

  async getIdStructure(id: number, responseTimeoutMs?: number): Promise<any> {
    throw new Error('Method not implemented.');
  }
}

import {
  Component,
  ElementRef,
  Input,
  Output,
  ViewChild,
  EventEmitter
} from '@angular/core';

export interface UploadFile {
  id: number;
  progress: number;
  file: File | null;
}

@Component({
    selector: 'mcp-file-select',
    templateUrl: './file-select.component.html',
    styleUrls: ['./file-select.component.scss'],
    standalone: false
})
export class FileSelectComponent {
  @ViewChild('fileDropRef', { static: false }) fileDropEl: ElementRef;
  @Input() title: string;
  @Output() fileSelectedEvent = new EventEmitter<any>();

  isMultiple = false;

  /**
   * on file drop handler
   */
  onFileDropped(evt: any) {
    this.fileSelectedEvent.emit(evt);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(evt: any) {
    this.fileSelectedEvent.emit(evt);
  }
}

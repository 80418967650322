import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

@Component({
    selector: 'mcp-gate-position-status-button',
    templateUrl: 'gate-position-status-button.component.html',
    styleUrls: ['gate-position-status-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class GatePositionStatusButtonComponent {
  @Input() label = '';
  @Input() status: boolean | null = false;

  @Output() buttonClicked: EventEmitter<void> = new EventEmitter<void>();

  onClick(): void {
    this.buttonClicked.emit();
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject, debounceTime, takeUntil } from 'rxjs';

@Component({
    selector: 'mcp-vertical-slider',
    templateUrl: 'vertical-slider.component.html',
    styleUrls: ['vertical-slider.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class VerticalSliderComponent implements OnInit, OnDestroy {
  @Input() minValue = 0;
  @Input() maxValue = 100;
  @Input() value: number = 0;
  @Input() disabled = false;
  @Output() valueChanged: EventEmitter<number> = new EventEmitter<number>();

  private readonly _value$ = new Subject<number>();
  private readonly _destroy$ = new Subject<void>();
  formControl = new FormControl<number>(0);

  ngOnInit(): void {
    this.formControl.setValue(this.value);
    this._value$
      .pipe(debounceTime(100), takeUntil(this._destroy$))
      .subscribe((value) => {
        this.valueChanged.emit(value);
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  onValueChange(): void {
    !this.formControl.value || this._value$.next(this.formControl.value);
  }
}

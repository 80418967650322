{
  "name": "mcp",
  "version": "0.0.16",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "start:dev": "ng serve --configuration development",
    "build": "ng build",
    "server": "node server.js",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "test-coverage": "ng test --code-coverage",
    "test-headless": "ng test --watch=false --browsers=ChromeHeadless --reporters=junit --code-coverage",
    "lint": "npx eslint 'src/**/*.{js,ts,html}' --fix",
    "pwa": "ng build && http-server -p 8080 -c-1 dist/mcp"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^19.0.6",
    "@angular/cdk": "^19.0.5",
    "@angular/common": "^19.0.6",
    "@angular/compiler": "^19.0.6",
    "@angular/core": "^19.0.6",
    "@angular/forms": "^19.0.6",
    "@angular/material": "^19.0.5",
    "@angular/platform-browser": "^19.0.6",
    "@angular/platform-browser-dynamic": "^19.0.6",
    "@angular/pwa": "^19.0.7",
    "@angular/router": "^19.0.6",
    "@angular/service-worker": "^19.0.6",
    "@ngneat/transloco": "^4.3.0",
    "@popperjs/core": "^2.11.8",
    "@types/web-bluetooth": "^0.0.20",
    "bootstrap": "^5.3.3",
    "buffer": "^6.0.3",
    "eslint-config-love": "^56.0.0",
    "keycloak-angular": "^19.0.2",
    "keycloak-js": "^24.0.5",
    "rxjs": "~7.4.0",
    "tslib": "^2.8.1",
    "zone.js": "^0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.0.7",
    "@angular-eslint/eslint-plugin": "^19.0.2",
    "@angular-eslint/eslint-plugin-template": "^19.0.2",
    "@angular-eslint/template-parser": "^19.0.2",
    "@angular/cli": "^19.0.7",
    "@angular/compiler-cli": "^19.0.6",
    "@types/jasmine": "~3.10.18",
    "@types/node": "^12.11.1",
    "@typescript-eslint/eslint-plugin": "^7.18.0",
    "eslint": "^8.57.1",
    "eslint-config-prettier": "^9.1.0",
    "eslint-plugin-import": "^2.31.0",
    "eslint-plugin-n": "^16.0.0 ",
    "eslint-plugin-prettier": "^5.2.1",
    "eslint-plugin-promise": "^6.6.0",
    "jasmine-core": "~3.10.0",
    "karma": "~6.3.0",
    "karma-chrome-launcher": "~3.1.0",
    "karma-coverage": "~2.1.0",
    "karma-jasmine": "~4.0.0",
    "karma-jasmine-html-reporter": "~1.7.0",
    "karma-junit-reporter": "^2.0.1",
    "prettier": "^3.4.2",
    "typescript": "5.5.4"
  }
}

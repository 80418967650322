import { Injectable } from "@angular/core";
import { InteractionType } from "@app/shared/enums/enums";
import { Interaction } from "@app/shared/interfaces/interfaces";

@Injectable({
  providedIn: 'root'
})
export class InteractionService {
  _latestInteraction: Interaction = {
    type: InteractionType.UNKNOWN,
    time: Date.now()
  };

  constructor() {}

  interactionTriggered(type: InteractionType) {
    this._latestInteraction = {
      type,
      time: Date.now()
    }
  }

  get latestInteraction() {
    return this._latestInteraction;
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

import { ListViewItem, SelectButton } from './interfaces';
import { Action, SelectorSize } from '@app/shared/enums/enums';

@Component({
    selector: 'mcp-list-view',
    templateUrl: 'list-view.component.html',
    styleUrls: ['list-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ListViewComponent {
  Action = Action;
  @Input() items: ListViewItem[] = [];

  @Input() selectorSize: SelectorSize = SelectorSize.MEDIUM;

  @Output() itemUrlClicked: EventEmitter<ListViewItem> =
    new EventEmitter<ListViewItem>();

  @Output() itemActionClicked: EventEmitter<ListViewItem> =
    new EventEmitter<ListViewItem>();

  @Output() selectChange: EventEmitter<ListViewItem> =
    new EventEmitter<ListViewItem>();

  @Output() selectButtonClicked: EventEmitter<SelectButton> =
    new EventEmitter<SelectButton>();

  @Output() dragEndEvent: EventEmitter<ListViewItem> = new EventEmitter<ListViewItem>();

  itemClickHandler(item: ListViewItem): void {
    this.itemUrlClicked.emit(item);
  }

  actionClickHandler(item: ListViewItem): void {
    this.itemActionClicked.emit(item);
  }

  onSelectChange(evt: ListViewItem) {
    this.selectChange.emit(evt);
  }

  selectButtonClickHandler(button: SelectButton) {
    this.selectButtonClicked.emit(button);
  }

  onDragEnd(item: ListViewItem) {
    this.dragEndEvent.emit(item);
  }
}

<div
  class="grid-card"
  [ngClass]="{ 'disabled default-pointer': gridCardElement.disabled }"
  (click)="!gridCardElement.disabled && onCardClick()"
>
  <div class="d-flex flex-column">
    <div
      class="d-flex align-items-center justify-content-center icon-container"
      [ngClass]="gridCardElement.backgroundColor"
    >
      <img [src]="'./assets/icons/' + gridCardElement.iconName + '.svg'" [alt]="gridCardElement.iconName" />
    </div>
    <div class="py-1 px-2 grid-card-text">
      <p class="title">{{ gridCardElement.title | transloco}}</p>
      <p class="description">{{ gridCardElement.subtitle | transloco }}</p>
    </div>
  </div>
</div>

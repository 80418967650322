import { Component, Input, OnChanges } from '@angular/core';

export enum StatusSignalType {
  OK = 'ok',
  WARN = 'warn',
  ALARM = 'alarm',
  NEUTRAL = 'neutral',
  ERROR = 'error'
}
@Component({
    selector: 'mcp-status-signal',
    templateUrl: 'status-signal.component.html',
    styleUrls: ['./status-signal.component.scss'],
    standalone: false
})
export class StatusSignalComponent implements OnChanges {
  @Input() title: string;
  @Input() type: StatusSignalType = StatusSignalType.NEUTRAL;
  actualTitle: string | undefined;

  ngOnChanges() {
    this.actualTitle = this.title;
    if (!this.title || typeof this.title !== 'string') {
      switch (this.type) {
        case StatusSignalType.OK:
          this.actualTitle = 'common.ok';
          break;
        case StatusSignalType.WARN:
          this.actualTitle = 'commin.warning';
          break;
        case StatusSignalType.ALARM:
          this.actualTitle = 'common.seriousIssue';
          break;
        case StatusSignalType.ERROR:
          this.actualTitle = 'common.error';
          break;
        default:
          this.actualTitle = 'None';
      }
    }
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { InteractionService } from '@app/core/services/interaction.service';
import { InteractionType } from '@app/shared/enums/enums';

@Component({
    selector: 'mcp-press-release-button',
    templateUrl: 'press-release-button.component.html',
    styleUrls: ['press-release-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PressReleaseButtonComponent {
  @Input() disabled: boolean = false;
  @Output() buttonPressed: EventEmitter<void> = new EventEmitter<void>();
  @Output() buttonReleased: EventEmitter<void> = new EventEmitter<void>();

  private _isButtonCurrentlyPressed: boolean = false;

  constructor(private readonly _interactionService: InteractionService) {

  }

  buttonPressedHandler(event: Event): void {
    event.preventDefault();
    this._isButtonCurrentlyPressed = true;
    this.buttonPressed.emit();
    this._interactionService.interactionTriggered(InteractionType.BUTTON_PRESSED);
  }

  contextMenuHandler(event: Event): void {
    event.preventDefault();
  }

  buttonReleasedHandler(event: Event): void {
    event.preventDefault();
    this._isButtonCurrentlyPressed = false;
    this.buttonReleased.emit();
    this._interactionService.interactionTriggered(InteractionType.BUTTON_RELEASED);
  }

  buttonLeftHandler(event: Event): void {
    if (!this._isButtonCurrentlyPressed) {
      return;
    }

    this.buttonReleasedHandler(event);
  }
}

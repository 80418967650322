import { Component, OnInit } from '@angular/core';
import { LANGUAGES } from '@app/app.config';
import { Language } from '@app/shared/interfaces/interfaces';
import { LanguageService } from '@app/shared/services/language.service';
import { getDeepCopy } from '@app/utils';

@Component({
    selector: 'mcp-language-selector',
    templateUrl: './language-selector.component.html',
    styleUrls: ['./language-selector.component.sass'],
    standalone: false
})
export class LanguageSelectorComponent implements OnInit {
  selectedLanguage: Language;
  languages: Language[];
  constructor(private readonly _languageService: LanguageService) {}

  ngOnInit() {
    this.languages = getDeepCopy(LANGUAGES);
    this.languages.forEach((language: Language) => {
      if (language.locale === this._languageService.getLanguage()) {
        this.selectedLanguage = language;
      }
    });
  }

  updateLanguage() {
    !this.selectedLanguage || this._languageService.setLanguage(this.selectedLanguage);
  }
}

import { Subscription } from 'rxjs';

export abstract class RegisterService {
  subscriptions: Subscription[] = [];
  isInitialized = false;
  serviceId = 'unknown';
  constructor() {}

  init() {
    this.isInitialized = true;
  }

  reset() {
    console.log('[RegisterService] ' + this.serviceId + ' - RESET');
    this.isInitialized = false;
    this.subscriptions.forEach((item) => item.unsubscribe());
    this.subscriptions = [];
  }
}

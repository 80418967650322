import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NotificationService } from '@shared/components/notification/notification.service';

@Component({
    selector: 'mcp-notification-dialog',
    templateUrl: 'notification.component.html',
    styleUrls: ['notification.component.scss'],
    standalone: false
})
export class NotificationComponent {
  @Input() title!: string;
  @Input() hasCloseButton: boolean = false;
  @Output() closeButtonClicked = new EventEmitter<void>();
  @Output() dialogAutoClosed = this._notificationService.autoCloseEvent;

  isVisible$ = this._notificationService.visible$;

  constructor(private readonly _notificationService: NotificationService) {}

  closeClickHandler(): void {
    this.closeButtonClicked.next();
  }
}
